.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
:root {
  --light-shadow: rgb(255, 255, 255);
  --dark-shadow: rgb(190, 190, 190);

  --neu-shadow-inset: inset -1.5px -1.5px 2px var(--light-shadow),
    inset 1.5px 1.5px 2px var(--dark-shadow);

  --neu-datatable-shadow: inset -2px -2px 2px var(--dark-shadow),
    inset 2px 2px 2px var(--light-shadow) !important;
}

.neu-shadow {
  box-shadow: var(--neu-datatable-shadow), 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
}

.mesh {
  background-color: rgb(245, 243, 255);
  background-image: radial-gradient(at 9% 10%, rgb(255, 251, 235) 0, transparent 55%),
    radial-gradient(at 58% 21%, rgb(255, 228, 230) 0, transparent 44%),
    radial-gradient(at 83% 19%, rgb(191, 219, 254) 0, transparent 57%),
    radial-gradient(at 37% 54%, rgb(245, 208, 254) 0, transparent 40%);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
